import AddIcon from '@material-ui/icons/Add';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

export default function AddGroupForm(props) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        id: '',
        name: '',
    })

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const handleSubmit = () => {
        setLoading(true)
        Api().groups.save(props.user.accessToken, state)
            .then(() => {
                state.id = '';
                state.name = '';

                props.loadList()
                setOpen(false)
            })
            .catch(err => console.log(err))
            .then(() => setLoading(false))
    }

    return (
        <span>
            <Tooltip title={'Add group'}>
                <IconButton color={'inherit'} onClick={() => setOpen(true)}><AddIcon/></IconButton>
            </Tooltip>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add Group</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="id"
                        label="ID"
                        value={state.id}
                        onChange={handleChange}
                        autoComplete={'off'}
                        helperText={'Generated if empty'}
                        fullWidth
                    />
                    <TextField
                        required
                        name="name"
                        label="Name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete={'off'}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
                    {
                        loading
                            ? <Box component="span" style={{minWidth: 70, maxHeight: 36}}><CircularProgress/></Box>
                            : <Button onClick={handleSubmit} variant="contained" color="primary" style={{minWidth: 70}}>Save</Button>
                    }
                </DialogActions>
            </Dialog>
        </span>
    );
}
