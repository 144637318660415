import React, {createContext, useEffect, useState} from 'react';
import {auth} from '../services/firebase';

export const UserContext = createContext({identity: null, loading: true, accessToken: null, isAdmin: false})

const Provider = (props) => {
    const [state, setState] = useState({
        identity: null,
        loading: true,
        accessToken: null,
        isAdmin: false,
    })

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user === null) {
                setState({
                    identity: null,
                    accessToken: null,
                    loading: false,
                    isAdmin: false,
                })
                return
            }

            const {displayName, email, photoURL} = user;

            setState({
                identity: {
                    displayName,
                    email,
                    photoURL,
                },
                accessToken: user.za,
                loading: false,
                isAdmin: user.email === 'igor.chornij@gen.tech'
            })
        })
    }, [])

    return (
        <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
    )
}
export default Provider
