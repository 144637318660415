import Dashboard from './components/Dashboard';
import Login from './components/Login';
import React from 'react';
import UserProvider from './providers/UserProvider';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

export default function App() {
    return (
        <UserProvider>
            <Router>
                <div className="App">
                    <Switch>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route path="/">
                            <Dashboard/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </UserProvider>
    );
}
