import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GoogleButton from 'react-google-button';
import React, {useContext, useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import {UserContext} from '../providers/UserProvider';
import {signInWithGoogle} from '../services/firebase';

export default function Login() {
    const user = useContext(UserContext)
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        if (user.identity !== null) {
            setRedirect('/')
        }
    }, [user])

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    if (user.loading) {
        return <Backdrop open style={{zIndex: 9999, color: '#fff'}}><CircularProgress color="inherit"/></Backdrop>
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            style={{minHeight: '100vh'}}
        >
            <Grid item xs={3}>
                <GoogleButton onClick={signInWithGoogle}/>
            </Grid>
        </Grid>
    );
}
