function getApiDomain() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://api.tasker.test'
    } else {
        return 'https://api.tasker.boosters-dev.com'
    }
}

function Api() {
    function authHeaders(accessToken) {
        let headers = new Headers();
        headers.set('Authorization', accessToken);

        return headers;
    }

    function parseError(json) {
        if (json.hasOwnProperty('error')) {
            return json.error;
        } else if (json.hasOwnProperty('Message')) {
            return json.Message;
        } else if (json.hasOwnProperty('message')) {
            return json.message;
        } else {
            return json.toString();
        }
    }

    return {
        groups: {
            load: (accessToken) => {
                return fetch(getApiDomain() + '/list-all', {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            save: (accessToken, payload) => {
                return fetch(getApiDomain() + '/groups', {method: 'POST', headers: authHeaders(accessToken), body: JSON.stringify(payload)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id) => {
                return fetch(getApiDomain() + '/groups/' + id, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            update: (accessToken, id, payload) => {
                return fetch(getApiDomain() + '/groups/' + id, {method: 'PUT', headers: authHeaders(accessToken), body: JSON.stringify(payload)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
        },
        tasks: {
            save: (accessToken, payload) => {
                return fetch(getApiDomain() + '/tasks', {method: 'POST', headers: authHeaders(accessToken), body: JSON.stringify(payload)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id) => {
                return fetch(getApiDomain() + '/tasks/' + id, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            update: (accessToken, id, payload) => {
                return fetch(getApiDomain() + '/tasks/' + id, {method: 'PUT', headers: authHeaders(accessToken), body: JSON.stringify(payload)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            listEvents: (accessToken, id) => {
                return fetch(getApiDomain() + '/tasks/events/' + id, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
        },
    }
}

export default Api
