import React from 'react';
import Task from './Task';
import GroupMenu from './menus/GroupMenu';
import Box from '@material-ui/core/Box';

class Group extends React.Component {
    render() {
        let group = this.props.group;

        if (!group.tasks || group.tasks.length === 0) group.tasks = [];

        return <div key={group.id} className={'group'}>
            <h2 className={'group-name'}>
                {group.name}

                {this.props.user.isAdmin && <GroupMenu loadList={this.props.loadList} user={this.props.user} group={group}/>}

                <Box mt={1} style={{float: 'right', color: 'darkgray'}}>{group.events_count}</Box>
            </h2>

            <div>
                {group.tasks.map((task) => {
                    return <Task user={this.props.user} loadList={this.props.loadList} key={task.id} task={task}/>;
                })}
            </div>
        </div>;
    }
}

export default Group
