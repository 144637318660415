import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import UpdateTaskToolbar from './forms/UpdateTaskToolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import Api from '../utils/api';
import LinearProgress from '@material-ui/core/LinearProgress';

const reactStringReplace = require('react-string-replace')

class Task extends React.Component {
    state = {
        list: null,
        loading: false,
    }

    loadEvents = () => {
        if (this.state.loading || this.state.list !== null) {
            return
        }

        this.setState({list: null, loading: true})

        Api().tasks.listEvents(this.props.user.accessToken, this.props.task.id)
            .then(data => {
                this.setState({list: data, loading: false})
            })
            .catch(err => console.log(err))
    }

    render() {
        let task = this.props.task;

        function Finished(event) {
            if (event.finished === null) return;

            return <Moment date={event.finished} format="YYYY/MM/DD HH:mm:ss"/>
        }

        function Duration(event) {
            if (event.elapsed === 0) return;

            return moment.duration(event.elapsed, 'seconds').humanize();
        }

        function TaskName() {
            if (task.events_count === 0) {
                return <Typography style={{textDecoration: 'line-through', color: 'gray'}}>{task.name}</Typography>
            }

            return <Typography>{task.name}</Typography>
        }

        function Status() {
            if (task.events_count === 0 || task.stats.wait_ending) {
                return null
            }

            if (task.stats.is_ok) {
                return <Tooltip title="All ok">
                    <DoneAllIcon style={{color: 'green'}}/>
                </Tooltip>
            } else {
                return <Tooltip title="Task failed">
                    <DoneAllIcon style={{color: 'red'}}/>
                </Tooltip>
            }
        }

        function Started() {
            if (task.started.length > 0 || task.stats.wait_ending) {
                return <Tooltip title={'Playing events: ' + task.started.length}>
                    <PlayArrowIcon style={{color: 'green'}}/>
                </Tooltip>
            }

            return null
        }

        function WarningCount() {
            if (task.stats.warnings.count > 0) {
                return <Tooltip title={'Warnings in events reports: ' + task.stats.warnings.count}>
                    <WarningIcon style={{color: 'orange'}}/>
                </Tooltip>
            }

            return null
        }

        function ErrorsCount() {
            if (task.stats.errors.count > 0) {
                const icon = task.stats.errors.last_affected
                    ? <ErrorIcon style={{color: 'red'}}/>
                    : <ErrorOutlineIcon style={{color: 'red'}}/>

                return <Tooltip title={'Errors in events reports: ' + task.stats.errors.count}>
                    {icon}
                </Tooltip>
            }

            return null
        }

        function FailedCount() {
            if (task.stats.failed > 0) {
                return <Tooltip title={'Recent events have failed: ' + task.stats.failed}>
                    <SmsFailedIcon style={{color: 'red'}}/>
                </Tooltip>
            }

            return null
        }

        function NoEventsForALongTime() {
            if (task.stats.last_event_duration !== '') {
                return <Tooltip title={`Last event was ${task.stats.last_event_duration} ago`}>
                    <HourglassFullIcon/>
                </Tooltip>
            }

            return null
        }

        function PrintReport(props) {
            if (!props.report) {
                return null
            }

            return Object.keys(props.report).map(function (key) {
                return <div key={key}>
                    <Moment date={key} format="YYYY/MM/DD HH:mm:ss"/>
                    &nbsp;
                    <FormatReportLine line={props.report[key]}/>
                </div>
            })
        }

        function FormatReportLine(props) {
            let line = reactStringReplace(props.line, 'WARNING', (match) => (
                <b style={{color: 'darkorange'}}>{match}</b>
            ));

            line = reactStringReplace(line, 'ERROR', (match) => (
                <b style={{color: '#ff3d3d'}}>{match}</b>
            ));

            return line
        }

        return <Box className={'task'} ml={5} mb={1}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={this.loadEvents}>
                    <TaskName/>&nbsp;{task.events_count > 0 ? <sup>{task.events_count}</sup> : ''}
                    <Box ml={1}>
                        <Status/>
                        <Started/>
                        <FailedCount/>
                        <ErrorsCount/>
                        <WarningCount/>
                        <NoEventsForALongTime/>
                    </Box>
                    <Box mt={1} style={{marginLeft: 'auto', color: 'gray'}}>
                        <Tooltip title={'Max duration for all events: ' + task.max_duration}>
                            <span>{task.max_current_duration}</span>
                        </Tooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper style={{width: '100%'}}>
                        {
                            this.props.user.isAdmin &&
                            <div>
                                <Box mt={3} ml={2} style={{display: 'inline-block', width: 200}}>{task.id}</Box>
                                <Toolbar style={{float: 'right'}}>
                                    {this.props.user.isAdmin && <UpdateTaskToolbar loadList={this.props.loadList} user={this.props.user} task={task}/>}
                                </Toolbar>
                            </div>
                        }

                        <TableContainer>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: 200}}>ID</TableCell>
                                        <TableCell style={{width: 175}}>Created</TableCell>
                                        <TableCell style={{width: 175}}>Finished</TableCell>
                                        <TableCell style={{width: 200}} align="right">Elapsed</TableCell>
                                        <TableCell align="left">Report</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list === null
                                        ? <TableRow><TableCell colSpan="5" style={{padding: 0}}><LinearProgress/></TableCell></TableRow>
                                        : this.state.list.map((event) => (
                                            <TableRow key={event.id}>
                                                <TableCell align="right">
                                                    {event.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Moment date={event.created} format="YYYY/MM/DD HH:mm:ss"/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Finished(event)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Duration(event)}
                                                </TableCell>
                                                <TableCell style={{width: '100%', fontSize: 11}}>
                                                    <PrintReport report={event.report}/>
                                                    {event.event_details && event.event_details.length > 0 && event.event_details.map(events => (
                                                        <>
                                                            <hr/>
                                                            <PrintReport report={events}/>
                                                        </>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </Box>;
    }
}

export default Task
