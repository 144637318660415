import AddGroupForm from './forms/AddGroupForm';
import Alert from '@material-ui/lab/Alert';
import Api from '../utils/api';
import AppBar from '@material-ui/core/AppBar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Group from './Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, {useContext, useEffect, useState} from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import {Redirect} from 'react-router-dom';
import {UserContext} from '../providers/UserProvider';
import {logOut} from '../services/firebase';
import {makeStyles} from '@material-ui/core/styles';

export default function Dashboard() {
    const user = useContext(UserContext);
    const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err === undefined ? 'Undefined error' : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (user.loading) {
            // console.log('Dashboard. Loading...')
        } else if (!user.identity) {
            setRedirect('/login');
        } else {
            // console.log('Dashboard. Loaded user:', user);
        }

        if (!user.accessToken) return

        setLoading(true);

        Api().groups.load(user.accessToken)
            .then(data => setList(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user])

    const useStyles = makeStyles((theme) => ({
        grow: {
            flexGrow: 1,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        statuses: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
    }));

    const classes = useStyles();

    const handleLogOut = () => {
        logOut()
    }

    let initialState = {
        groups: [],
        stats: {
            total_groups: 0,
            total_tasks: 0,
            total_events: 0,
            total_failed: 0,
            total_errors: 0,
            total_warnings: 0,
            success_rate: null,
        },
    };
    const [list, setList] = useState(initialState)

    function loadList() {
        setList(initialState)

        setLoading(true)
        Api().groups.load(user.accessToken)
            .then(data => setList(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    function SuccessRate() {
        if (list.stats.success_rate !== null) {
            let icon = '🍀'

            if (list.stats.success_rate < 90) {
                icon = '😈'
            }

            if (list.stats.success_rate < 50) {
                icon = '🤬'
            }

            return <span>
                <Tooltip title="Success rate">
                    <Badge badgeContent={icon}>
                        <span style={{textShadow: '0px 0px 5px black', marginLeft: 15}}>
                            {(Math.round(list.stats.success_rate * 100) / 100)}%
                        </span>
                    </Badge>
                </Tooltip>
            </span>
        }

        return ''
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    if (user.identity === null) {
        return user.loading ? <Backdrop open style={{zIndex: 9999, color: '#fff'}}><CircularProgress color="inherit"/></Backdrop> : ''
    }

    return (
        <div>
            <CssBaseline/>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                        Tasker
                    </Typography>

                    <div className={classes.statuses}>
                        <span style={{marginRight: 15}}>
                            {
                                list.stats.total_groups > 0 &&
                                <Tooltip title={'Total groups: ' + list.stats.total_groups}>
                                    <Badge badgeContent={list.stats.total_groups} style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                        <GroupWorkIcon style={{textShadow: '0px 0px 5px black'}}/>
                                    </Badge>
                                </Tooltip>
                            }
                            {
                                list.stats.total_tasks > 0 &&
                                <Tooltip title={'Total tasks: ' + list.stats.total_tasks}>
                                    <Badge badgeContent={list.stats.total_tasks} style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                        <AssignmentIcon style={{textShadow: '0px 0px 5px black'}}/>
                                    </Badge>
                                </Tooltip>
                            }
                            {
                                list.stats.total_events > 0 &&
                                <Tooltip title={'Total events: ' + list.stats.total_events}>
                                    <Badge badgeContent={list.stats.total_events} style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                        <TrendingUpIcon style={{textShadow: '0px 0px 5px black'}}/>
                                    </Badge>
                                </Tooltip>
                            }
                        </span>
                        {
                            list.stats.total_events - list.stats.total_failed > 0 &&
                            <Tooltip title={'Success events: ' + (list.stats.total_events - list.stats.total_failed)}>
                                <Badge badgeContent={list.stats.total_events - list.stats.total_failed}
                                       style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                    <DoneAllIcon style={{color: 'green'}}/>
                                </Badge>
                            </Tooltip>
                        }
                        {
                            list.stats.total_failed > 0 &&
                                <Tooltip title={'Failed events: ' + list.stats.total_failed}>
                                    <Badge badgeContent={list.stats.total_failed} style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                        <ErrorIcon style={{color: 'red'}}/>
                                    </Badge>
                                </Tooltip>
                        }
                        {
                            list.stats.total_errors > 0 &&
                                <Tooltip title={'Report errors: ' + list.stats.total_errors}>
                                    <Badge badgeContent={list.stats.total_errors} style={{marginRight: 15, textShadow: '0px 0px 5px black'}} max={9999}>
                                        <ErrorOutlineIcon style={{color: 'red'}}/>
                                    </Badge>
                                </Tooltip>
                        }
                        {
                            list.stats.total_warnings > 0 &&
                                <Tooltip title={'Report warnings: ' + list.stats.total_warnings}>
                                    <Badge badgeContent={list.stats.total_warnings} style={{marginRight: 15}} max={9999}>
                                        <WarningIcon style={{color: 'orange'}}/>
                                    </Badge>
                                </Tooltip>
                        }
                        <SuccessRate/>
                    </div>

                    <div className={classes.grow}/>

                    <Tooltip title={'Refresh'}>
                        <IconButton color={'inherit'} onClick={loadList}><SyncIcon/></IconButton>
                    </Tooltip>
                    {user.isAdmin && <AddGroupForm loadList={loadList} user={user}/>}
                    {user.identity && <Avatar alt={user.identity.displayName} title={user.identity.displayName} src={user.identity.photoURL}/>}
                    <Button onClick={handleLogOut} color="inherit">Log out</Button>
                </Toolbar>
                {loading && <LinearProgress/>}
            </AppBar>

            <Box mt={10} mb={2}>
                <Container maxWidth="lg">
                    {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                        () => setAlert({...alert, open: false})
                    }>{alert.text}</Alert>}

                    <div>
                        {list.groups.map((group) => {
                            return (
                                <Group key={group.id} loadList={loadList} group={group} user={user}/>
                            );
                        })}
                    </div>
                </Container>
            </Box>
        </div>
    );
}
