import React, {useEffect, useState} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Api from '../../utils/api';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

export default function UpdateGroupForm(props) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        name: '',
    })

    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    const errorAlert = (err) => {
        setAlert({open: true, success: false, text: err === undefined ? 'Undefined error' : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        setState(props.group)
    }, [props.group])

    const handleOpen = () => {
        props.closeMenu()
        setOpen(true);
    }

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const handleSubmit = () => {
        setLoading(true)
        setAlert({open: false})

        Api().groups.update(props.user.accessToken, props.group.id, state)
            .then(() => {
                state.name = '';

                props.loadList()
                setOpen(false)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    return (
        <div>
            <MenuItem onClick={handleOpen}>
                <EditIcon fontSize="small"/>
                Update
            </MenuItem>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Update Group {props.group.name}</DialogTitle>
                <DialogContent>
                    {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                        () => setAlert({...alert, open: false})
                    }>{alert.text}</Alert>}

                    <TextField
                        required
                        name="name"
                        label="Name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
                    {
                        loading
                            ? <Box component="span" style={{minWidth: 70, maxHeight: 36}}><CircularProgress/></Box>
                            : <Button onClick={handleSubmit} variant="contained" color="primary" style={{minWidth: 70}}>Update</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
