import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';

export default function AddTaskForm(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        id: '',
        group: props.group.id,
        name: '',
    });

    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    const errorAlert = (err) => {
        setAlert({open: true, success: false, text: err === undefined ? 'Undefined error' : err[0].toUpperCase() + err.slice(1)})
    }

    const handleOpen = () => {
        setAlert({open: false})
        props.closeMenu()
        setOpen(true)
    }

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const handleSubmit = () => {
        setLoading(true)
        setAlert({open: false})

        Api().tasks.save(props.user.accessToken, state)
            .then(() => {
                state.id = '';
                state.name = '';

                props.loadList()
                setOpen(false)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    return (
        <div>
            <MenuItem onClick={handleOpen}>
                <AddIcon fontSize="small"/>
                Add Task
            </MenuItem>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add Task for group `{props.group.name}`</DialogTitle>
                <DialogContent>
                    {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                        () => setAlert({...alert, open: false})
                    }>{alert.text}</Alert>}

                    <TextField
                        margin="dense"
                        name="id"
                        label="ID"
                        value={state.id}
                        onChange={handleChange}
                        autoComplete={'off'}
                        helperText={'Generated if empty (prefer format {sb|wb|lb}-{service}-{action})'}
                        fullWidth
                    />
                    <TextField
                        required
                        name="name"
                        label="Name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete={'off'}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
                    {
                        loading
                            ? <Box component="span" style={{minWidth: 70, maxHeight: 36}}><CircularProgress/></Box>
                            : <Button onClick={handleSubmit} variant="contained" color="primary" style={{minWidth: 70}}>Save</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
