import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Api from '../../utils/api';
import UpdateGroupForm from '../forms/UpdateGroupForm';
import AddTaskForm from '../forms/AddTaskForm';

export default function GroupMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleClose()

        if (window.confirm('Confirm delete')) {
            Api().groups.delete(props.user.accessToken, props.group.id)
                .then(() => {
                    props.loadList()
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <span>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <UpdateGroupForm loadList={props.loadList} group={props.group} closeMenu={handleClose} user={props.user}/>
                <MenuItem onClick={handleDelete}><DeleteIcon fontSize="small"/> Delete</MenuItem>
                <AddTaskForm closeMenu={handleClose} loadList={props.loadList} user={props.user} group={props.group}/>
            </Menu>
        </span>
    );
}
