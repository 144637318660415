import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Api from '../../utils/api';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';

export default function UpdateTaskToolbar(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [state, setState] = React.useState({
        name: '',
    })

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const handleSubmit = () => {
        setShowLoading(true)
        Api().tasks.update(props.user.accessToken, props.task.id, state)
            .then(() => {
                state.name = '';

                props.loadList()
                handleClose()
            })
            .then(() => setShowLoading(false))
    }

    const [showLoading, setShowLoading] = React.useState(false)

    const handleDelete = () => {
        if (window.confirm('Confirm delete')) {
            Api().tasks.delete(props.user.accessToken, props.task.id)
                .then(() => {
                    props.loadList()
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <ButtonGroup>
            <Button onClick={handleClickOpen}>
                <EditIcon fontSize="small"/>
            </Button>
            <Button onClick={handleDelete}><DeleteIcon style={{color: 'red'}}/></Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update Task {props.task.name}</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        name="name"
                        label="Name"
                        value={state.name || props.task.name}
                        onChange={handleChange}
                        autoComplete={'off'}
                        fullWidth
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    {
                        showLoading
                            ? <Box component="span" style={{minWidth: 70, maxHeight: 36}}><CircularProgress/></Box>
                            : <Button onClick={handleSubmit} variant="contained" color="primary" style={{minWidth: 70}}>Update</Button>
                    }
                </DialogActions>
            </Dialog>
        </ButtonGroup>
    );
}
