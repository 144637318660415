import firebase from 'firebase/app'
import 'firebase/auth';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyAhCXdrgApUeviUjCo7jvIUx8NIF9wPBNQ',
        authDomain: 'tasker-6ae3a.firebaseapp.com',
        projectId: 'tasker-6ae3a',
        storageBucket: 'tasker-6ae3a.appspot.com',
        messagingSenderId: '401307340484',
        appId: '1:401307340484:web:4930bf7997fea5fd4ec391',
        measurementId: 'G-Z8CTJPPRNF',
    });
}

export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()

export const signInWithGoogle = () => {
    auth.signInWithPopup(googleProvider)
        .then(res => {
            if (!res.user.email || !res.user.email.includes('@gen.tech')) {
                console.log('Wrong email domain')
                auth.signOut()
            }
        })
        .catch(error => {
            console.log('sign in with google error:', error.message);
        })
}

export const logOut = () => {
    auth.signOut()
        .then(() => {
            console.log('Logged out');
        })
        .catch((error) => {
            console.log('Logout error:', error.message);
        })
}
